<template>
  <div
    class="sms-assets px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="SMS Testing Assets" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full mb-6">
        <Paragraph
          ><p>
            Safety Management Services, Inc. has significant resources to
            accomplish DOT/DOD testing for Class 1 (explosive), Class 4
            (Flammable Solids), and Class 5 (Oxidizing Agents and Organic
            Peroxides). We list below some of the testing assets available to
            our clients. SMS also uses these assets to complete
            <router-link
              title="In-Process Testing"
              to="/testing/in-process-simulation-testing"
              >In-Process testing</router-link
            >.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Liberty Data Acquisition System" />
        <Paragraph
          ><p>
            <img
              class="alignleft size-full wp-image-12169"
              src="@/assets/Testing/daq_small-min.png"
              alt="daq_small"
              width="100"
              height="100"
            />
          </p>
          <p>
            SMS’s rugged data acquisition system (HBM Liberty DAQ System) can be
            used to fulfill TB 700-2 DoD Hazards Classification or MIL-STD-398
            Operational Shield testing requirements. The system can be
            configured for stand-alone operation or for real-time monitoring,
            control, and analysis via remote laptop. SMS can transport this
            system to the client’s facility, along with dynamic sensors to
            measure blast overpressure, noise, and temperature. Below is an
            example plot of the overpressure for an event.
          </p>
          <p>
            The system can be powered by either 90 – 260 VAC or 12 – 35 VDC
            (supplied by line or generator), and is equipped with an internal
            battery backup (2 – 20 hours, depending upon configuration).
            Real-time monitoring is accomplished via a laptop computer
            (controller) and a TCP/IP network connection; SMS can provide
            network routers and associated wiring for remote monitoring up to
            660 feet from the controller or wireless monitoring for longer
            distances (requires line of sight between wireless transceivers).
            The system can be started manually or off a trigger (switch closure
            or signal threshold) with pre- and post-trigger actions. The system
            is equipped with 8 GB of onboard high-speed memory, enabling
            acquisition of tests lasting miliseconds or hours.
          </p>
          <p>
            <img
              class="alignright size-full wp-image-12170"
              src="@/assets/Testing/overpressure-min.png"
              alt="overpressure"
              width="350"
              height="92"
            /><br />
            Our data acquisition system can be used to collect data from various
            sensors (each shielded cable directly grounded to earth to reduce
            signal noise) that may be mounted to a fixed stand, threaded into a
            test port, or secured to one of our tripods (to simulate the
            position of personnel). The sensitivity of each sensor can be
            directly input into the data acquisition system, enabling on-screen
            meters to display each sensor’s real-time value. Sensors frequently
            supplied by SMS for TB 700-2 or MIL-STD-398 tests include:
          </p>
          <ul>
            <li>
              Blast overpressure: ICP piezoelectric blast pressure pencil probes
              and mountable sensors (NPT thread) with a ±5 Volt output over a
              range of 0 – 50 psig or 0 – 500 psig.
            </li>
            <li>
              Noise: ICP piezoelectric microphones with a ±2.5 Volt output over
              an SPL range of 190 dB (8.3 psig).
            </li>
            <li>
              Heat Flux: Water-cooled Schmidt-Boelter gauges with a 0 – 10 mV
              output over 0.5 BTU/(ft²·sec) [5.7 kW/m²].
            </li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Fisher-Johns Auto-Ignition (FJAI)" />
        <Paragraph
          ><p>
            <img
              class="alignleft size-full wp-image-12171"
              src="@/assets/Testing/heatsensor_small-min.png"
              alt="heatsensor_small"
              width="100"
              height="75"
            /><br />
            We use high-precision and high-sensitivity heat flux gauges to
            monitor the heat-flux at a distance away from the event (fire or
            other). Heat flux is a function of the temperature between two items
            and the media separating them. If the sensor heats up, the heat flux
            changes.<br />
            <img
              class="alignright size-full wp-image-12172"
              src="@/assets/Testing/heatflux-min.png"
              alt="heatflux"
              width="350"
              height="92"
            /><br />
            We use water-cooled Schmidt-Boelter gauges with a 0 – 10 mV output
            over 0.5 BTU/(ft²·sec) [5.7 kW/m²] to limit the heating of the
            heat-flux sensor. An example plot of heat flux at various distances
            is above.
          </p>
        </Paragraph>
        <TitleComponent title="Pin Switches" />
        <Paragraph
          ><p>
            <img
              src="@/assets/Testing/SLSGT-min.jpg"
              alt="Super Large Scale Gap Test"
              width="200"
              height="298"
              class="alignleft size-full wp-image-12174"
            /><br />
            Pin switches are used to measure detonation velocities. In addition,
            super-large-scale gap testing can be completed and instrumented to
            evaluate the sample’s shock sensitivity level. The pin switches are
            used in conjunction with the below oscilloscope for data collection.
            Below is an image showing an instrumented super-large-scale gap test
            with the pin switches inserted down the length of the article.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Oscilloscope" />
        <Paragraph
          ><p>
            <img
              src="@/assets/Testing/toscope-min.png"
              alt="toscope"
              width="250"
              height="165"
              class="alignleft size-full wp-image-12175"
            /><br />
            SMS uses a Tektronix Oscilloscope to capture very high frequency
            events such as those logged by pin switches.&nbsp; The oscilloscope
            can also be used for lower frequency events when for example we’re
            monitoring electrostatic charge buildup during processing or other
            specific event.&nbsp; The oscilloscope has 4-channels where each
            channel can capture at a maximum frequency of 2.5 GHz and can be
            remote controlled . It has an 8 bit vertical resolution.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="PCB Pressure Probes" />
        <Paragraph
          ><p>
            <img
              src="@/assets/Testing/probe_2-min.png"
              alt="probe_2"
              width="517"
              height="204"
              class="alignleft size-full wp-image-12177"
            /><br />
            SMS has multiple pressure probes to record both side-on and
            reflective overpressure events.&nbsp; We also have both dynamic and
            static probes to monitor internal pressures generated during for
            instance the time-pressure test or during oxidizing liquids testing
            in the
            <router-link
              title="Time-Pressure Apparatus"
              to="/test-equipment/time-pressure-apparatus"
              >time-pressure apparatus</router-link
            >. We frequently use the blast pencil probes for DOD testing
            according to&nbsp;TB 700-2:Department of Defense Ammunition and
            Explosives Hazard Classification Procedures and work place safety as
            specified in DoD 6055.9 (Ammo &amp; Explosive Safety), DoD 4145.26M
            (Contractor Safety Manual) and other applicable regulations.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Phantom High Speed Camera" />
        <Paragraph
          ><p>
            <img
              class="alignleft size-full wp-image-12178"
              src="@/assets/Testing/v210-min.png"
              alt="Phantom High Speed Camera"
              width="200"
              height="200"
            /><br />
            SMS has the capability to film high-speed events with a
            <a
              title="v210 Phantom"
              href="http://www.visionresearch.com/Products/High-Speed-Cameras/v210"
              >v210 Phantom high-speed camera</a
            >. We have used the camera to evaluate blast effects, measure
            fragment velocities, and to evaluate reactions during sensitivity
            testing (see
            <router-link to="/test-equipment/godetect-esd">here</router-link>
            for further info on using a Phantom high-speed video camera to
            determine reaction outcome.)
          </p>
          <p>
            Below is a high-speed video of a spark initiating smokeless powder
            in an ABL ESD Machine.
          </p>
        </Paragraph>
        <div style="position: relative;width: 100%;padding-bottom: 56.25%;">
          <iframe
            src="https://player.vimeo.com/video/489986907"
            style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 0;"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Differential Scanning Calorimeter (DSC)" />
        <Paragraph
          ><p>
            <img
              src="@/assets/Testing/DSC131-min.png"
              alt="DSC131"
              width="180"
              height="152"
              class="alignleft size-full wp-image-12179"
            /><br />
            SMS uses a Seteram DSC131 to screen energetic materials for thermal
            sensitivity as is commonly done throughout the explosives industry.
            We have used it to perform compatibility testing although we
            strongly prefer using larger sample sizes in the SBAT to complete
            compatibility testing. For further information on the SBAT and it’s
            advantages over other test equipment click
            <router-link title="SBAT Machine" to="/test-equipment/sbat"
              >here</router-link
            >.
          </p>
        </Paragraph>
        <TitleComponent title="Thermal Imaging Camera" />
        <Paragraph
          ><p>
            <img
              src="@/assets/Testing/SC600camera-min.png"
              alt="SC600 Thermal Imaging Camera"
              width="175"
              height="113"
              class="alignleft size-full wp-image-12180"
            /><br />
            Safety Management Services, Inc. has a FLIR SC600 Series thermal
            imaging camera to monitor the temperature of propellant, insulation,
            and case during case cutting operations. The camera’s detector is an
            uncooled microbolometer and has a spectral range from 7.5 – 13
            micrometers with a resolution of 640 x 480. The standard temperature
            range of the camera is from 0 degrees to 650 degrees Centigrade.
          </p>
        </Paragraph>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/4"></div>
      <div class="w-full md:w-1/2 mb-6">
        <TitleComponent title="Sensitivity Test Equipment" />
        <Paragraph
          ><p>
            <img
              src="@/assets/Testing/friction-icon-min.png"
              alt="ABL Friction Machine"
              width="275"
              height="255"
              class="alignleft size-full wp-image-12181"
            /><br />
            In addition to frequently using explosive sensitivity equipment,
            Safety Management Services, Inc. manufactures sensitivity equipment
            for friction, impact, thermal, ESD, and other stimulus testing. We
            use and supply Impact, Friction, ESD, SBAT, Time-Pressure, Koenen,
            Impingement Guns, and other sensitivity testing equipment. For
            further information on our sensitivity test equipment and the
            benefits of working in engineering units, click
            <router-link title="Testing Equipment" to="/test-equipment"
              >here.</router-link
            >
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/4"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Testing Assets",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has significant resources to perform all testing. Assets include data acquisition systems, Fisher-Johns Auto-Ignition (FJAI), Pin Switches, Oscilloscope, PCB Pressure Probes, High-Speed Camera, and sensitivity testing equipment."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.sms-assets {
  .alignleft {
    display: inline;
    float: left;
    margin-right: 15px;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 15px;
  }

  a {
    color: #aa222c;
  }
}
</style>
